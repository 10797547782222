@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal
	font-display: swap

$icon-check-mark: "\EA02"
$icon-whats-app: "\EA03"
$icon-telegram: "\EA04"
$icon-facebook: "\EA05"
$icon-instagram: "\EA06"
$icon-aim: "\EA07"
$icon-mail: "\EA08"
$icon-arrow: "\EA09"
$icon-calendar: "\EA10"
$icon-facebook2: "\EA11"
$icon-eyes1: "\EA12"
$icon-eyes2: "\EA13"
$icon-cross: "\EA14"
$icon-tiktok: "\EA15"
$icon-user: "\EA16"
$icon-heart: "\EA17"
$icon-heart-full: "\EA18"
$icon-star: "\EA19"
$icon-star-full: "\EA20"
$icon-sideleft: "\EA21"
$icon-search: "\EA22"
$icon-point: "\EA23"
$icon-phone: "\EA24"
$icon-triangle: "\EA25"
$icon-pen: "\EA26"
$icon-trash: "\EA27"
$icon-clock: "\EA28"
$icon-plus: "\EA29"
$icon-file: "\EA30"
$icon-arrow-left: "\EA31"
$icon-message: "\EA32"
$icon-refresh: "\EA33"
$icon-user2: "\EA34"
$icon-menu: "\EA35"
$icon-shape: "\EA36"
$icon-logout: "\EA37"
$icon-checkmark-thin: "\EA38"
$icon-user-circle: "\EA39"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-check-mark:before
	content: $icon-check-mark
.icon-whats-app:before
	content: $icon-whats-app
.icon-telegram:before
	content: $icon-telegram
.icon-facebook:before
	content: $icon-facebook
.icon-instagram:before
	content: $icon-instagram
.icon-aim:before
	content: $icon-aim
.icon-mail:before
	content: $icon-mail
.icon-arrow:before
	content: $icon-arrow
.icon-calendar:before
	content: $icon-calendar
.icon-facebook2:before
	content: $icon-facebook2
.icon-eyes1:before
	content: $icon-eyes1
.icon-eyes2:before
	content: $icon-eyes2
.icon-cross:before
	content: $icon-cross
.icon-tiktok:before
	content: $icon-tiktok
.icon-user:before
	content: $icon-user
.icon-heart:before
	content: $icon-heart
.icon-heart-full:before
	content: $icon-heart-full
.icon-star:before
	content: $icon-star
.icon-star-full:before
	content: $icon-star-full
.icon-sideleft:before
	content: $icon-sideleft
.icon-search:before
	content: $icon-search
.icon-point:before
	content: $icon-point
.icon-phone:before
	content: $icon-phone
.icon-triangle:before
	content: $icon-triangle
.icon-pen:before
	content: $icon-pen
.icon-trash:before
	content: $icon-trash
.icon-clock:before
	content: $icon-clock
.icon-plus:before
	content: $icon-plus
.icon-file:before
	content: $icon-file
.icon-arrow-left:before
	content: $icon-arrow-left
.icon-message:before
	content: $icon-message
.icon-refresh:before
	content: $icon-refresh
.icon-user2:before
	content: $icon-user2
.icon-menu:before
	content: $icon-menu
.icon-shape:before
	content: $icon-shape
.icon-logout:before
	content: $icon-logout
.icon-checkmark-thin:before
	content: $icon-checkmark-thin
.icon-user-circle:before
	content: $icon-user-circle
